import React, { useEffect, useState } from "react";
import { Form, Row, Col, Tab, Tabs, Card, Container } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import dataServices from "../../apiServices/data.services";
import { useAlert } from "react-alert";
import { Can } from "./../../context/AuthProvider";
import { handleValidationError } from "../../components/CommonFunctions";
import FileUpload from "../../components/FileUploader/FileUpload";
import AssetsView from "../../components/FileUploader/AssetsView";
import Prescription from "./Prescription";
import AddDiagnosis from "./AddDiagnosis";

const AppointmentDetails = () => {
  const { id } = useParams();
  const [appointment, setAppointment] = useState();
  const [loading, setLoading] = useState(false);
  const alert = useAlert();
  const [reload, setReload] = useState(false);

  useEffect(() => {
    setLoading(true);
    dataServices
      .getAppointmentDetails(id)
      .then((res) => {
        if (res.status === 200) {
          setAppointment(res.data);
        }
      })
      .catch((err) => {
        alert.error(handleValidationError(err));
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id, reload]);

  return (
    <Container className="content-wrapper">
      <Row className="justify-content-center">
        <Can I="read" an="Appointment">
          <Col>
            <Card className="bg-white mt-4 rounded-4 mb-3">
              <a
                href="#"
                className="icon-link p-2 btn-outline-primary"
                onClick={(e) => {
                  e.preventDefault();
                  window.history.back();
                }}
              >
                <span className="material-symbols-outlined">arrow_back</span>
                Back
              </a>
              <Card.Body>
                {appointment ? (
                  <>
                    <Can not I="edit" a="appointment">
                      <Link
                        to={`/edit-appointment/${id}`}
                        className="link-dark"
                      >
                        <div className="edit-profile-icon bg-primary text-white">
                          <span className="material-symbols-outlined h2 m-0">
                            edit
                          </span>
                        </div>
                      </Link>
                    </Can>
                    <div className="profile-card">
                      {/* Patient Info */}
                      <div className="detail-section">
                        <h2>Appointment Details</h2>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="detail-item">
                              <label>Doctor Name:</label>
                              <span> {appointment.doctor?.name} </span>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="detail-item">
                              <label>Date:</label>
                              {appointment.date + ", " + appointment.time}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="detail-item">
                              <label>Status:</label>
                              <span>  {appointment.status} </span>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="detail-item">
                              <label>Fee:</label>
                              {appointment.fee}
                            </div>
                          </div>
                        </div>
                        <br />

                        <h2>Patient Details</h2>
                        <div className="row">
                          <h5> {appointment.patient?.name}</h5>
                          <div className="col-md-6">
                            <div className="detail-item">
                              <label>DOB:</label>
                              <span>{appointment.patient.date_of_birth}</span>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="detail-item">
                              <label>Gender:</label>
                              <span>{appointment.patient.gender}</span>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="detail-item">
                              <label>Weight:  </label>
                              <span>{appointment?.weight}Kg</span>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="detail-item">
                              <label>Height:</label>
                              <span>
                                {appointment?.height}cm
                              </span>
                            </div>
                          </div>
                        </div>
                        <br />
                        <h2 >Package Details</h2>
                        {appointment?.package &&
                          appointment?.package.id !== null ? (
                          <div className="row">
                             <div className="col-md-6">
                              <div className="detail-item">
                                <label>Package Name:</label>
                                <span> {appointment?.package.name}</span>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="detail-item">
                                <label>Seating Number:</label>
                                <span>{appointment?.seating_no}</span>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="row">
                            <div className="col-md-6">
                              <div className="detail-item">
                                <label>Package Name:</label>
                                <span className="text-danger">
                                  No active package
                                </span>
                              </div>
                            </div>
                          </div>
                        )} 
                        <br />
                         <h2>Diagnosis Details</h2>
                        <div className="row"> 
                          <div className="col-md-6">
                            <div className="detail-item">
                              <label>Initial Consultation:</label>
                              <span>{appointment.details}</span>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="detail-item">
                              <label>Diagnosis:</label>
                              <span> {appointment.diagnosis} </span>
                            </div>
                          </div>
                        </div>
                        <br />
                      </div>
                    </div>
                    <br/>
                    {/* <Row className="d-flex">
                      <Col>
                        <Card.Text>
                          <span className="text-muted small">Doctor Name</span>
                          <br />
                          {appointment.doctor?.name}
                        </Card.Text>
                      </Col>
                      <Col>
                        <Card.Text>
                          <span className="text-muted small">Date & Time</span>
                          <br />
                          {appointment.date + ", " + appointment.time}
                        </Card.Text>
                      </Col>
                    </Row>
                    <br />
                    <Row className="d-flex">
                      <Col>
                        <Card.Text>
                          <span className="text-muted small">Patient Name</span>
                          <Link></Link>
                          <br />
                          {appointment.patient?.name}
                        </Card.Text>
                      </Col>
                      <Col>
                        <Card.Text>
                          <span className="text-muted small">Age</span>
                          <br />
                          {appointment.patient.date_of_birth}
                        </Card.Text>
                      </Col>
                    </Row>
                    <br />
                    <Row className="d-flex">
                      <Col>
                        <Card.Text>
                          <span className="text-muted small">Weight</span>
                          <br />
                          {appointment?.weight}
                          <span>Kg</span>
                        </Card.Text>
                      </Col>
                      <Col>
                        <Card.Text>
                          <span className="text-muted small">Height</span>
                          <br />
                          {appointment?.height}
                          <span>Cm</span>
                        </Card.Text>
                      </Col>
                    </Row>{" "}
                    <br />
                    {appointment?.package &&
                      appointment?.package.id !== null &&
                      appointment?.package.available_count >= 0 ? (
                      <Row>
                        <Col sm={6}>
                          <Form.Group className="form-group">
                            <Form.Label>Selected Package</Form.Label>
                            <span> {appointment?.package.name}</span>
                          </Form.Group>
                        </Col>
                        <Col sm={6}>
                          <Form.Group className="form-group">
                            <Form.Label>Seating Number</Form.Label>
                            <span>{appointment?.seating_no}</span>
                          </Form.Group>
                        </Col>
                      </Row>
                    ) : (
                      <Row>
                        <Col>
                          <Form.Group className="form-group">
                            <Form.Label>Selected Package</Form.Label>
                            <span className="text-danger">
                              No active package
                            </span>
                          </Form.Group>
                        </Col>
                      </Row>
                    )}
                    <Row className="d-flex">
                      <Col>
                        <Card.Text>
                          <span className="text-muted small">Gender</span>
                          <br />
                          {appointment.patient.gender}
                        </Card.Text>
                      </Col>
                      <Col>
                        <Card.Text>
                          <span className="text-muted small">Fees</span>
                          <br />
                          {appointment.fee}
                        </Card.Text>
                      </Col>
                    </Row>
                    <br />
                    <Row className="d-flex">
                      <Col>
                        <Card.Text>
                          <span className="text-muted small">Status</span>
                          <br />
                          {appointment.status}
                        </Card.Text>
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col>
                        <Card.Text>
                          <span className="text-muted small">Note</span>
                          <br />
                          {appointment.details}
                        </Card.Text>
                      </Col>
                      <Col>
                        <Card.Text>
                          <span className="text-muted small">Diagnosis</span>
                          <br />
                          {appointment.diagnosis}
                        </Card.Text>
                      </Col>
                    </Row> */}
                  </>
                ) : (
                  loading && <Loader />
                )}
              </Card.Body>
            </Card>
          </Col>
        </Can>
      </Row>

      <Tabs
        defaultActiveKey="diagnosis"
        id="uncontrolled-tab-example"
        className="mb-3"
        mountOnEnter={true}
        unmountOnExit={true}
      >
        <Tab eventKey="diagnosis" title="Diagnosis">
          <AddDiagnosis appointment={appointment} />
        </Tab>
        <Tab eventKey="uploadDocument" title="Upload Doc">
          <p className="ms-3"> Upload Documents</p>
          <FileUpload
            id={id}
            uploadApi={"uploadAppointmentDocs"}
            assets={appointment?.assets}
            setReload={setReload}
          />
          <AssetsView
            id={id}
            editApi={"editAppointments"}
            assets={appointment?.assets}
            setReload={setReload}
          />
        </Tab>
        <Tab eventKey="prescription" title="Prescription">
          <Can I="list" a="Prescription">
            <Prescription appointment={appointment} />
          </Can>
        </Tab>
      </Tabs>
      <br />
      <br />
    </Container>
  );
};

export default AppointmentDetails;
