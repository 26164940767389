import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import dataServices from "../../apiServices/data.services";
import Loader from "../../components/Loader/Loader";
import { Button, Card, Col, Container, Row, Table } from "react-bootstrap";
import { Can } from "../../context/AuthProvider";
import { handleValidationError } from "../../components/CommonFunctions";
import { useAlert } from "react-alert";
import PDFViewerComponent from "../../components/PDFViewer/PdfViewer";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

const Bill = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [invoice, setInvoice] = useState();
  const [showInvoice, setShowInvoice] = useState(false);
  const alert = useAlert();
  useEffect(() => {
    setLoading(true);
    dataServices
      .getInvoiceDetails(id)
      .then((res) => {
        if (res.status === 200) {
          setInvoice(res.data);
        }
      })
      .catch((err) => {
        alert.error(handleValidationError(err));
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleSend = (invoice) => {
    const mobile = invoice?.patient?.mobile;
    // Create WhatsApp link 
    const message = `Dear ${invoice?.patient?.name}, Your invoice no. ${invoice.id}  of Rs. ${invoice.amount} is now due. Please make the payment as soon as possible. If you have already paid, ignore it. The invoice URL is https://${window.location.hostname}/print-invoice/${invoice.rnd_number}   Thank you!`;
  // Open WhatsApp Web with the message
   // window.open(whatsappLink, '_blank');  
    const encodedMessage = encodeURIComponent(message);
    const whatsappWebUrl = `https://web.whatsapp.com/send?phone=${mobile}&text=${encodedMessage}`;


   return whatsappWebUrl;
  };

  const printDocument = () => {
    const input = document.getElementById("divToPrint");
    html2canvas(input, { scale: 2, useCORS: true }).then((canvas) => { 
      const imgData = canvas.toDataURL("image/jpeg", 0.7); // Adjust quality (0.1-1) 
      const pdf = new jsPDF("p", "mm", "a4");

      const imgWidth = 210; // A4 width
      const pageHeight = 297; // A4 height
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;

      let position = 0;
      pdf.setFontSize(14);
      pdf.text("Additional Text", 10, 280); // Adds text at specified position on the page


      pdf.addImage(imgData, "JPEG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, "JPEG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      pdf.save("prescription.pdf");
    });
  };


  return (
    <div className="content-wrapper">
      <Can I="read" an="Invoice">
        <div className="mt-4">
          <Card className="rounded-4 px-3 pt-3 overflow-hidden mb-3">
            <Card.Body>
              <Card.Title as="h6" className="pb-2">
                Invoice Info
              </Card.Title>
              {invoice ? (
                <>
                  <Row className="d-flex">
                    <Col>
                      <p>
                        <span className="text-muted small">Patient Name</span>
                        <br />
                        {invoice.patient.name}
                      </p>
                    </Col>
                    <Col>
                      <p>
                        <span className="text-muted small">Date</span>
                        <br />
                        {invoice.payment_date}
                      </p>
                    </Col>
                  </Row>
                  <Row className="d-flex">
                    <Col>
                      <p>
                        <span className="text-muted small">Invoice No.</span>
                        <br />
                        {invoice.id}
                      </p>
                    </Col>
                    <Col>
                      <p>
                        <span className="text-muted small">Payment Method</span>
                        <br />
                        {invoice.paid_by}
                      </p>
                    </Col>
                  </Row>
                  <Row className="d-flex">
                    <Col>
                      <p>
                        <span className="text-muted small">
                          Transaction ID or cheque number
                        </span>
                        <br />
                        {invoice.transaction_number}
                      </p>
                    </Col>
                    <Col>
                      <p>
                        <span className="text-muted small">Amount</span>
                        <br />
                        {invoice.amount}
                      </p>
                    </Col>
                  </Row>
                  <Row className="d-flex">
                    <Col className="text-end">
                    {showInvoice && (
                        <a href={handleSend(invoice)} className="btn btn-primary me-2" target="_blank" rel="noopener noreferrer">
                        <i class="fa-brands fa-whatsapp"></i>Share on WhatsApp
                      </a>
                      )}
                      <Button
                        variant="primary"
                        onClick={() =>
                          showInvoice ? printDocument() : setShowInvoice(true)
                        }
                        size="sm"
                      >
                        {showInvoice ? "Print" : "Print preview"}
                      </Button>
                      {showInvoice && (
                        <Button
                          variant="danger"
                          className="ms-2"
                          onClick={() => setShowInvoice(false)}
                          size="sm"
                        >
                          Close
                        </Button>
                      )}
                    </Col>
                  </Row>
                  {/* <Can I="edit" an="Invoice">
                    <a
                      href={`/edit-invoice/${invoice.id}`}
                      className="link-dark"
                    >
                      <div className="edit-profile-icon bg-primary text-white">
                        <span className="material-symbols-outlined h2 m-0">
                          edit
                        </span>
                      </div>
                    </a>
                  </Can> */}
                </>
              ) : (
                loading && <Loader />
              )}
            </Card.Body>
          </Card>
        </div>
      </Can>
      {showInvoice && <PDFViewerComponent invoice={invoice} />}
    </div>
  );
};

export default Bill;
